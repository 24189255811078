<template>
  <b-modal
    id="remark-form-modal"
    title="หมายเหตุ"
    hide-footer
  >
    <validation-observer
      ref="remarkForm"
    >
      <form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('fields.remark')"
          rules="required"
        >
          <b-form-group
            :label="`${$t('fields.remark')} *`"
            label-for="remark"
          >
            <b-form-textarea
              id="remark"
              v-model="remarkForm.note"
              :state="errors[0] ? false : null"
              :readonly="readonly"
            />
          </b-form-group>
        </validation-provider>
        <div class="d-flex justify-content-between">
          <div>
            <b-button
              variant="dark"
              :disabled="readonly"
              @click="onResetRemark"
            >
              {{ $t('buttons.clear') }}
            </b-button>
          </div>
          <div>
            <b-button
              variant="light"
              class="mr-2"
              @click="closeModal"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-overlay
              :show="isLoading"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="readonly"
              >
                {{ $t('buttons.confirm') }}
              </b-button>
            </b-overlay>
          </div>
        </div>
      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from '@validations'

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      required,

      remarkForm: {
        note: '',
      },
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.player.isUpdatingPlayer,
    }),
    ...mapGetters(['playerDetail']),
  },
  watch: {
    value(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['fetchPlayer', 'updatePlayer']),
    setData() {
      if (this.value) {
        const data = JSON.parse(JSON.stringify(this.value))
        this.fetchPlayer(this.value.id)
        this.remarkForm = { ...data }
      } else {
        this.remarkForm = { note: '' }
      }
    },
    closeModal() {
      this.$bvModal.hide('remark-form-modal')
    },
    async onSubmit() {
      this.$refs.remarkForm.validate().then( async (success) => {
        if (success) {
          const form = {
            playerId: this.value.id,
            data: {
              note: this.remarkForm.note,
              firstName: this.playerDetail.profile.firstname || this.value.firstname,
              lastName: this.playerDetail.profile.lastname || this.value.lastname,
              bankAccountNumber: this.playerDetail.bankAccount.bankAccountNumber,
              bankCode: this.playerDetail.bankAccount.bankCode,
            },
            search: this.search
          }
          await this.updatePlayer(form)
          this.$emit('finish')
        }
      })
      
    },
    async onResetRemark() {
      const form = {
        playerId: this.value.id,
        data: {
        note: '',
        firstName: this.playerDetail.profile.firstname || this.value.firstname,
        lastName: this.playerDetail.profile.lastname || this.value.lastname,
        bankAccountNumber: this.playerDetail.bankAccount.bankAccountNumber,
        bankCode: this.playerDetail.bankAccount.bankCode,
        }
      }
      await this.updatePlayer(form)
      this.$emit('finish')
    },
  },
}
</script>